//body
body {
    border-top: 5px solid #A5D4AD;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $dark-gray!important;
        font-weight: bold;
    }

    color:$dark-gray!important;

    a{color: $dark-gray;}
}
