//header
header {
    @include xy-grid-container($global-width);
    padding: 0 0.5em;

    @include breakpoint(medium) {
        padding: 0 60px;
    }
}

.subheader {
    @include breakpoint (small down) {
        margin-bottom: 0 !important;
    }
}

.top-bar {
    @include top-bar-container;
    // @include xy-grid;
    // border: 1px solid #000;
    background: #fff !important;
    margin: 0 !important;
    padding: 0 !important;

    @include breakpoint(medium) {
        height: rem-calc(90);
    }

    &-left {

        // @include xy-cell(5);
        @include breakpoint(medium) {
            @include xy-cell(12);
            @include xy-grid;
        }

        @include flex-align-self(bottom);

        >div {
            @include xy-cell(6);
            margin: 0;
            padding: 0;
            // @include flex;
        }
    }

    &-right {

        // @include xy-cell(2);
        @include breakpoint(medium) {
            @include xy-cell(8);
            margin: 0;
            padding-right: 10px;
        }

        .pc-top-menu {
            @include menu-base;
            @include menu-simple($margin:1em);
            background-color: #fff !important;
            @include hide-for-only(small);
        }
    }
}

.pc-top-btn {
    @include button(false, $secondary-color, $white, $white, solid);
    font-size: rem-calc(16);
    font-weight: bold;
    // width: 142px;
    width: 43%;
    height: 55px;
    border-radius: 5px;
    // vertical-align: middle;
    border: 2px solid $secondary-color;
    box-sizing: border-box;
    padding-top: 1.1em;
    position: relative;

    &:hover{
        color: $secondary-color;
    }

    a {
        display: block;
        color: $white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        &:hover {
            color: $secondary-color;
        }
    }
}

.top-icon1 {
    @include xy-cell(6, $gutters:1em, $gutter-position:right);
    padding-bottom: 0.5em;

    @include breakpoint(medium) {
        margin-top: 0.6em;
    }
}

.top-icon2 {
    @include breakpoint(small down) {
        @include xy-cell(4, $gutters:3px);
    }

    position: relative;
    margin-top: -3px;
    top: 3px;
}

//nav
.pc-nav {
    width: 100%;

    background-color: #A5D4AD;
    @include hide-for-only(small);

    @include breakpoint(medium) {
        height: 77px;
    }

    &-menu {
        @include xy-grid-container($global-width, 100px);
        padding-top: 10px;
        @include xy-grid;
        color: $dark-gray;

        button {
            @include xy-cell(4);
            @include button(true, $white, #FFFCD1, $dark-gray, solid);
            font-size: 24px;
            border: 4px dotted#80C86B;
            position: relative;
            border-radius: 5px;
            font-weight: bold;
            padding: 7px;
            position: relative;
            border-radius: 5px;
            // width: 100%;
            min-width: 170px;
            height: 57px;
            font-size: 18px;

            @include breakpoint(1100px) {
                width: 319px;
                height: 57px;
                font-size: 24px;
            }

            &::before {
                content: '';
                display: inline-block;
                background-size: contain;
                vertical-align: middle;
                position: absolute;
                background-image: url("../../assets/img/navicon.svg");
                width: 27px;
                height: 27px;
                top: 10px;
                left: 15px;

                @include breakpoint(1100px) {
                    width: 33px;
                    height: 33px;
                    top: 7px;
                    left: 35px;

                }
            }
        }
    }
}



// smp-menu

.smp-top-bar {
    @include xy-grid;
}

.smp-menu-btn {
    position: fixed;
    top: 1.5%;
    right: 3%;
    width: 46px;
    height: 46px;
    text-align: center;
    cursor: pointer;
    z-index: 3;
    background-image: url("../../assets/img/menu-btn.svg");

    &::after {
        // content: attr(data-txt-menu);
        display: inline-block;
        background-image: url("../../assets/img/menu-btn.svg");
        content: '';
        width: 46px;
        height: 46px;
    }
}

.open .smp-menu-btn {
    // content: attr(data-txt-close);

    &:after {
        display: inline-block;
        background-image: url("../../assets/img/menu-btn-close.svg");
        content: '';
        width: 46px;
        height: 46px;
    }
}

.smp-menu {
    position: fixed;
    // display: table;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(165, 212, 173, 0.9);
    transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
    z-index: 2;

    .button1 {
        @include button($background:$white, $background-hover:$light-gray, $color:$secondary-color);
        padding: 0 !important;
        font-size: 21px;

        a {
            // display: block;
            color: $secondary-color;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .button2 {
        @include button($background:$secondary-color, $background-hover:$secondary-color, $color:$white);
        padding: 0 !important;
        font-size: 16px;

        a {
            // display: block;
            color: $white;

            &:hover {
                opacity: 0.5;
            }
        }

    }
}

.smp-menu .menu1 {
    @include menu-base;
    // display: table-cell;
    // vertical-align: middle;
    // font-size: 24px;
    font-weight: bold;
    width: 100%;
    padding-top: 30%;
    margin: 0 auto;
    text-align: center;

    >li {
        padding: 0 !important;
        width: 90%;
        margin: 0.5em auto;
    }
}

.smp-menu .menu2 {
    @include menu-base;
    // display: table-cell;
    // vertical-align: middle;
    // font-size: 21px;
    font-weight: bold;
    margin: 0 auto;
    text-align: center;
    padding: 0 5% 0.5em;

    >li {
        padding: 0 !important;
        width: 47%;
        margin: 1em 0;
    }
}

.smp-menu-bd {
    border-bottom: 3px dotted #fff;
    margin: 0 auto;
    width: 90%;
}

.smp-sub-menu {
    @include menu-base;
    margin-top: 1em !important;
    padding-left: 6%;
    font-weight: bold;
    color: $dark-gray;

    >li {
        margin: 0 1em 1em 0;
        display: flex;
        line-height: 18px;
    }
}



/* 開閉用ボタンがクリックされた時のスタイル */
.open .smp-menu {
    // cursor: url(../images/cross.svg),auto;
    // -webkit-transition: all .5s;
    transition: all 0.3s;
    visibility: visible;
    opacity: 1;
}

// .smp-menu {
//     @include xy-cell(2, $gutters:2px);
//     padding-bottom: 0.5em;
// }


.smp-top-hr {
    margin: 0;
    padding: 0;
    width: 100%;
    height: rem-calc(16);
    border: none;
    background-color: $col-main;
    position: relative;
}