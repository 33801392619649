.subtitle {
    border-bottom: 3px solid $col-main;
    padding-bottom: 0.2em;

    &::before {
        background-image: url("../../assets/img/icon-clover.svg");
        content: '';
        display: inline-block;
        background-size: contain;
        vertical-align: bottom;
        // position: absolute;
        width: 28px;
        height: 28px;
        margin-right: 10px;
        // height: 27px;
        // top: 10px;
        // left: 15px;
    }
}

.fwb {
    font-weight: bold;
}

// page汎用

.content {
    width: 100%;

    @include breakpoint(large) {
        background-image: url("../../assets/img/bg-body.svg");
    }

    &-box {
        @include xy-grid-container($global-width, 1em);
        padding-top: 1em;
        padding-bottom: 3em;

        @include breakpoint(medium) {
            padding: 1em 50px 5em;
        }

        background-color: #fff;

        >section {
            margin-top: 2rem;

            @include breakpoint(medium) {
                margin-top: 4rem;
            }
        }
    }
}

.content-text-box {
    font-size: 18px;
    margin-top: 1em;
    color: $dark-gray;

    @include breakpoint(medium) {
        margin-top: 2em;
    }
}

.content-text-box2 {
    font-size: 1rem;
    margin-top: 1em;

    @include breakpoint(medium) {
        margin-top: 2em;
        padding-left: 1.5rem;
    }
}

.link-text1-bl {
    color: #E86BB3;
    border-bottom: 1px solid #E86BB3;

    a {
        display: block;

        &:hover {
            color: $secondary-color;
        }
    }
}

.mt1rem {
    margin-top: 1rem;
}

// index-page

.main-view {
    // width: 100%;
    // padding: 0;
    // margin: 0;

    background-color: #fff;

    @include breakpoint(large) {
        height: 480px;
    }

    &-box {
        padding: 0 !important;
        @include xy-grid-container;
        position: absolute;
        top: 203px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
    }
}

#bg-circle {

    // @include show-for-only(large);
    @include breakpoint(medium) {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 480px;
        overflow: hidden;
        z-index: 0;
        opacity: 0.5;
    }
}



.content-rinen {
    // @include xy-grid;

    .title {
        padding-top: 0.8em;

        @include breakpoint(small down) {
            // font-size: 16px;
        }
    }

    &-textbox {
        font-size: 18px;
        margin-top: 1em;

        @include breakpoint(medium) {
            margin-top: 2em;
        }
    }

}

.content-rinen-menu {
    margin: 1em 0 0 0;
    padding: 1.5em;

    @include breakpoint(medium) {
        margin: 4em 0 0 0;
        padding: 0;
    }

    .menubox {
        // width: 300px;
        // height: 245px;
        border-radius: 50px;
        text-align: center;
        margin: 1.2em;
        padding: 2em 1.75em 2em 1.75em;
        font-weight: bold;
        color: $dark-gray;

        @include breakpoint(medium) {
            margin: 0;
        }

        dt {
            font-size: 21px;
        }

        dd {
            margin-top: 3em;
        }
    }

    .bgc-1 {
        background-color: #A0D8E5;
    }

    .bgc-2 {
        background-color: #A5D4AD;
    }

    .bgc-3 {
        background-color: #FFF100;
    }
}

.content-shokai {
    margin-top: 4em;

    @include breakpoint(medium) {}
}

.content-shokai-box {
    margin-top: 1.5em;
    margin-bottom: 5em;

    @include breakpoint(medium) {}

    >div {
        margin: 1.2em;

        >a {
            display: block;
            color: $dark-gray;

            &:hover {
                color: $secondary-color;
            }
        }
    }

    .shokai-title {
        margin-top: 1.25em;
        font-size: 18px;
        font-weight: bold;
    }

    .shokai-time {
        margin-top: 0.5em;
    }

    .shokai-tel {
        margin: 10px 50px 20px;
        height: 30px;

        // min-width: 180px;
        @include breakpoint(medium) {
            margin: 10px 0;
            height: auto;
        }

        @include breakpoint(large) {
            margin: 10px 20px;
            height: auto;
        }

        background-color: $secondary-color;
        color: $white;
        font-weight: bold;
        border-radius: 50px;
        padding: 0.1em 0;

    }
}

.content-mokuhyo {
    margin-top: 2em;

    @include breakpoint(medium) {
        margin-top: 4em;
    }


    &-textbox {
        @extend .content-text-box;
        font-weight: bold;
        color: $dark-gray;
    }

}

.content-mokuhyo-menu {
    margin: 1em 5em 0 0;
    padding: 1.5em;

    @include breakpoint(medium) {
        margin: 4em 0 0 0;
        padding: 0;
    }

    .menubox {
        // width: 300px;
        max-width: 290px;
        min-height: 300px;
        border-radius: 20px;
        border: 5px solid;
        text-align: center;
        margin: 1.2em auto;
        padding: 1.5em 0.7em;
        color: $dark-gray;

        @include breakpoint(medium) {
            margin: 0;
        }

        dt {
            font-weight: bold;
            font-size: 20px;
        }

        dd {
            font-size: 16px;
            margin-top: 2em;
        }

    }

    .bgc-1 {
        // background-color: #A0D8E5;
        border-color: #A5D4AD;
        position: relative;

        &::before {
            position: absolute;
            content: '1';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #A5D4AD;
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            border-radius: 50%;
            top: -20px;
            left: -20px;
        }
    }

    .bgc-2 {
        border-color: #95CFC9;
        position: relative;

        &::before {
            position: absolute;
            content: '2';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #95CFC9;
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            border-radius: 50%;
            top: -20px;
            left: -20px;
        }
    }

    .bgc-3 {
        border-color: #EF8786;
        position: relative;

        &::before {
            position: absolute;
            content: '3';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #EF8786;
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            border-radius: 50%;
            top: -20px;
            left: -20px;
        }
    }
}

.content-shokai2 {
    margin: 2em 0;

    @include breakpoint(medium) {
        margin: 4.5em 0;
    }

    ul {
        li {
            list-style: none;
            margin-top: 3.5em;
            padding-right: 1em;
        }
    }

}

.content-shokai2-box {

    border-bottom: 3px dotted #A0D8E5;

    padding-bottom: 2em;

    @include breakpoint(medium) {
        padding-bottom: 3em;
    }

    >div {

        .name,
        .adress,
        .tel,
        .openingday,
        .type,
        .target {
            margin-bottom: 0em;

            @include breakpoint(medium) {
                margin-bottom: 1.7em;
            }

            // margin: 0;
        }
    }

    @include breakpoint(medium) {

        // max-height: 250px;

    }

    &-left {

        @include breakpoint(medium) {
            padding-right: 4.5em;
        }
    }

    &-right {
        // max-height: 250px;
        text-align: left;
        margin-top: 1.8em;

        @include breakpoint(medium) {
            font-size: 18px;
            margin-top: 0em;
        }

        dd {
            // margin-top:1.2em;
        }
    }

    .name {

        // text-align: left;
        @include breakpoint(small down) {
            padding-bottom: 1em;
        }

        .cell {
            text-align: center;

            @include breakpoint(medium) {
                padding: 0;
                text-align: left;
                margin-right: 0.5em;
                // margin-right: 0.5em;
            }
        }
    }

}


// information-page

.content-jikan-table {
    @include table();
    margin: 2em 0;

    th {
        border: 1px solid #6CBB5A;
        text-align: center;
        background-color: #E2EFDA;
        font-weight: normal;
    }

    td {
        text-align: center;
        border: 1px solid #6CBB5A;
        background-color: #FFF2CC;
        border-width: 0 1 1 1;
    }

    // display: table-cell;


}

// schedule-page
.content-schedule-day {

    @include breakpoint(medium) {
        // background-image: url("../../assets/img/bg-seikatsu.svg");
        background: url("../../assets/img/bg-seikatsu.svg") no-repeat;
        background-position: top 50px right 0;
    }

    &-table {
        @include table();

        thead,
        tbody,
        th,
        tr,
        td {
            background-color: transparent !important;
            border: none;
            font-size: 18px;
            margin: 0;
            padding: 0;
        }

        td:first-child {
            min-width: 100px;
            margin: 0;
            padding: 0;
            position: relative;
            padding-bottom: 3em;

            &::after {
                content: '|';
                position: absolute;
                top: 88px;
                left: 36px;
                width: 10px;
                height: 30px;
                color: #A5D4AD;
                background: #A5D4AD;
                border-radius: 10px;
            }
        }

        td:nth-child(2) {
            padding-bottom: 2.5em;
            font-weight: bold;


            @include breakpoint(medium) {
                padding-left: 50px;
                margin: 0;
            }

            >span {
                display: block;
                border-bottom: 3px dotted #A5D4AD;
            }
        }

        td:nth-child(3) {
            padding-bottom: 2.5em;
            text-align: center;

            @include breakpoint(medium) {
                text-align: left;
                padding-right: 150px;
                margin: 0;
            }

            >span {
                display: block;
                border-bottom: 3px dotted #A5D4AD;
                padding-left: 0;
                margin: 0;
            }
        }

        tr:last-child {
            >td:first-child {
                &::after {
                    content: none;
                }
            }
        }

    }
}

.content-schedule-year {

    @include breakpoint(medium) {
        // background-image: url("../../assets/img/bg-seikatsu.svg");
        // background: url("../../assets/img/bg-seikatsu.svg") no-repeat;
        background-position: top 50px right 0;
    }

    &-table {
        @include table();

        thead,
        tbody,
        th,
        tr,
        td {
            background-color: transparent !important;
            border: none;
            font-size: 18px;
            margin: 0;
            padding: 0;
        }

        td:first-child {
            min-width: 140px;
            margin: 0;
            position: relative;
            padding-bottom: 3em;

            @include breakpoint(medium) {
                min-width: 180px;
            }
        }

        td:nth-child(2) {
            padding-bottom: 2.5em;
            font-weight: bold;
            font-size: 16px;


            @include breakpoint(medium) {
                // padding-left: 50px;
                margin: 0;
                font-size: 18px;
            }

            >span {
                display: block;
                border-bottom: 3px dotted #A5D4AD;
            }
        }

        td:nth-child(3) {
            padding-bottom: 2.5em;
            text-align: center;
            font-size: 16px;

            @include breakpoint(medium) {
                text-align: left;
                padding-right: 100px;
                margin: 0;
                font-size: 18px;
            }

            >span {
                display: block;
                border-bottom: 3px dotted #A5D4AD;
                padding-left: 0;
                margin: 0;
            }
        }

    }
}

// recruit-page

.content-recruit-table {
    @include table();

    thead,
    tbody,
    th,
    tr,
    td {
        background-color: transparent !important;
        border: none;
        // font-size: 18px;
        // margin: 0;
        // padding: 0;
    }

    td {
        background-color: $white;
        font-size: 16px;
        border:none;
        border-bottom: 1px solid #6CBB5A;
        padding: 1.5rem 0.5rem;
    }

    td:nth-child(odd){
        width: 30%;
        // border-bottom: 5px solid #000;
        font-weight: bold;
    }
    td:nth-child(even){
        // border-bottom: 1px solid #000;
    }
}


// about&privacy-page

.content-privacy{

    ul{
        li{
            list-style-type: decimal;
            margin-bottom: 1.5rem;
        }
    }

}

