// footer
footer {
    width: 100%;
    background-color: $col-main;

    .footer-wrap {
        @include xy-grid-container($global-width, 100);
        padding: 1em;

        @include breakpoint(medium) {
            padding: 2em;
        }
    }

    .footer-box {
        // @include xy-grid;
        background-color: #fff;
        padding: 1em;
        border: 10px solid #FFFCD1;
        border-radius: 5px;
        font-weight: bold;
        color: $dark-gray;

        @include breakpoint(medium) {
            padding: 2em;
        }

        &-left {
            border-bottom: 3px dotted $col-main;
            // @include xy-cell(12);

            // @include breakpoint(medium) {
            //     padding: 2em;
            //     @include xy-cell(6);
            // }
        }

        &-right {
            // border: 1px solid #000;
            // @include xy-cell(6);

            &-menu {
                @include menu-base;
                @include menu-direction(vertical);
            }

            .button {
                margin: 10px 0 0 0 !important;
                font-weight: bold;
                color: $white;
                font-size: rem-calc(16) !important;
                height: 60px;

                &:hover{
                    border: 3px solid $secondary-color;
                    box-sizing: border-box;
                    background: #fff;
                    color: $secondary-color;
                }
            }
        }

        .address {
            margin: 0.5em auto;
            font-size: rem-calc(14);

            @include breakpoint(medium) {
                font-size: rem-calc(16);
            }
        }

        .tel-text1 {
            font-size: rem-calc(18);
        }

        .tel-text2 {
            font-size: rem-calc(21);
        }
    }

    .copy {
        background-color: $white;
    }
}

.footer-sub-menu {
    @include menu-base;
    margin-top: 0.5em !important;
    padding-left: 0.6em;

    >li {
        margin-right: 1em;
        display: flex;
        align-items: center;
    }
}

.footer-smp-menu {
    @include menu-base;
    @include menu-expand;
    @include menu-direction(vertical);
    z-index: 0;
    margin-bottom: 1em;

    li .button {
        margin: 1em 0 0 0 !important;
        font-weight: bold;
        color: $white !important;
        font-size: rem-calc(16) !important;
        height: 60px;
    }
    a{margin: 0;padding: 0;}
}

.tex-col-se {
    // color: $secondary-color;
    // font-weight: bold;
    background-image: url("../../assets/img/arrow-icon.svg");
    width: 18px;
}

.footer-icon {
    min-width: 200px;
    width: 50%;
    // margin-bottom: 1em;
}

